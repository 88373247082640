import { useAuthUserWatcher } from '@/scripts/composables/useAuthUserWatcher'
import { usePopstateNavigationListener } from '@/scripts/composables/usePopstateNavigationListener'
import { markdown } from '@/scripts/plugins/markdown'
import { formats as dateFormats } from '@/scripts/utils/date'
import resolveLang from '@/scripts/utils/resolve-lang'
import { resolvePage } from '@/scripts/utils/resolve-page'
import { flare } from '@flareapp/js'
import { flareVue } from '@flareapp/vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { modal } from '@vendor/emargareten/inertia-modal'
import { i18nVue } from 'laravel-vue-i18n'
import Notifications from 'notiwind'
import { createPinia } from 'pinia'
import { createApp, h } from 'vue'
import { createIntl } from 'vue-intl'
import '@/css/app.css'
import 'vue-advanced-cropper/dist/style.css'

const appName = window.document.getElementsByTagName('title')[0]?.textContent || 'Sliptree'

// Only enable Flare in production, we don't want to waste your quota while you're developing:
// eslint-disable-next-line node/prefer-global/process
if (process.env.NODE_ENV === 'production') {
    flare.light()
}

// ATTENTION: when making edits here, consider if they also apply to pdf.ts and ssr.ts!
createInertiaApp({
    title: title => `${title} - ${appName}`,
    resolve: resolvePage,
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) })
            .use(flareVue)
            .use(Notifications)
            .use(plugin)
            .use(
                createIntl({
                    locale: document.documentElement.lang,
                    defaultLocale: 'en',
                    formats: { date: dateFormats },
                }),
            )
            .use(markdown)
            .use(modal, { resolve: resolvePage })
            .use(createPinia())
            .use(i18nVue, {
                lang: document.documentElement.lang,
                fallbackLang: 'en',
                fallbackMissingTranslations: true,
                resolve: resolveLang,
                onLoad: () => {
                    // return early if app is mounted already
                    if (el && el.__vue_app__) {
                        return
                    }
                    vueApp.mount(el) // Mounted here so translations are loaded before vue.
                },
            })

        window.onload = function () {
            if (typeof plausible !== 'undefined') {
                plausible('Window', { props: { Resolution: `${window.innerWidth}x${window.innerHeight}` } })
                plausible('Screen', { props: { Resolution: `${screen.width}x${screen.height}` } })
            }
        }

        return vueApp
    },
    progress: {
        // The delay after which the progress bar will
        // appear during navigation, in milliseconds.
        delay: 2,

        // The color of the progress bar.
        color: '#ec4899',
    },
}).then(() => {
    useAuthUserWatcher()
    usePopstateNavigationListener()

    return console.debug('Inertia ready')
})

import.meta.glob(['../images/**'])
