import { watch } from 'vue'
import { flare } from '@flareapp/js'
import { usePage } from '@inertiajs/vue3'
import { BasePageProps } from '@/scripts/types/pages'

/**
 * This composable is used by page layout components to watch for changes in auth user.
 */
export const useAuthUserWatcher = () => {
    watch(
        () => usePage<BasePageProps>().props?.auth?.user,
        user => {
            flare.addContextGroup('user', {
                id: user?.id,
                email: user?.email,
                name: user?.name,
            })
        },
        { immediate: true },
    )
}
