import { onUnmounted } from 'vue'
import { router } from '@inertiajs/vue3'
import { useEventListener } from '@vueuse/core'

export const usePopstateNavigationListener = () => {
    let stale = false

    function handleNavigate(event) {
        const urlParts = event.detail.page.url.split('/')
        const validResource = ['invoices', 'estimates'].some(el => urlParts.includes(el))
        const validType = ['create', 'edit'].some(el => urlParts.includes(el))

        if (stale && validResource && validType) {
            router.get(event.detail.page.url, {}, { replace: true, preserveState: false })
        }

        stale = false
    }

    router.on('navigate', handleNavigate)

    useEventListener('popstate', () => (stale = true))
    onUnmounted(() => router.off('navigate', handleNavigate))
}
